import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import type { IFutureBookingsProps } from './FutureBookings';

const FutureBookings = lazy(async () => import('./FutureBookings'));

const el = '.fa-component--future-bookings';
const DOM = document.querySelector(el);

const render = function () {
    if (DOM) {
        const config = JSON.parse(DOM.getAttribute('data-props') ?? '{}') as IFutureBookingsProps;
        const token: HTMLInputElement = document.querySelector('input[name="__RequestVerificationToken"]')!;

        createRoot(DOM).render(<FutureBookings {...config} token={token.value} />);
    }
};

export default {
    render,
};
